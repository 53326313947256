import { NoSsr } from "@mui/base";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import type { FunctionComponent } from "react";
import { useEffect, useTransition } from "react";

import { useIsScrollingOverAppBarThreshold } from "scmp-app/components/app-bar";
import { HideElementOnScrollEffectThreshold } from "scmp-app/components/app-bar/consts";
import { useAppBarContext } from "scmp-app/components/app-bar/contexts";
import { useAppBarSetStickyScrollHeight } from "scmp-app/components/app-bar/hooks/sticky/app-bar-set-sticky-scroll-height";
import { BaseImage } from "scmp-app/components/common/base-image";
import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { hamburgerMenuAtom } from "scmp-app/components/hamburger-menu/atoms";
import IconMenu from "scmp-app/components/header/header-icon/menu.svg";
import type { HeaderConfiguration } from "scmp-app/components/header/types";
import { HomeEdition } from "scmp-app/components/home/edition";
import IconLoading from "scmp-app/components/home/header/header-icon/loading.gif";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";
import { useGetHomeAsPath } from "scmp-app/lib/router/hooks";
import { page as HomePage } from "scmp-app/pages/home";

import { DynamicHomeNewsTicker } from "./dynamics";
import { useHomeHeader } from "./hooks";
import {
  ActionButton,
  Container,
  Content,
  HeaderContainer,
  HomeHeaderContainer,
  LeftFeatureContainer,
  OnelineMenuContainer,
  RightFeatureContainer,
  StyledDynamicHeaderOnelineMenuContainer,
  StyledHomeMiniHeader,
  StyledLogo,
  StyledLogoLink,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
  headerConfiguration?: HeaderConfiguration;
};

export const HomeHeader: FunctionComponent<Props> = ({ className, headerConfiguration }) => {
  const { setShouldSwap } = useAppBarContext();
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();
  const { reference } = useAppBarSetStickyScrollHeight();
  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const isDesktopUp = useResponsive(theme.breakpoints.up("homeDesktop"));
  const threshold = isDesktopUp
    ? HideElementOnScrollEffectThreshold.desktop
    : HideElementOnScrollEffectThreshold.mobile;
  const shouldSwap = useIsScrollingOverAppBarThreshold(threshold);
  const { mastheadQueryParameter, renderFeatures } = useHomeHeader(
    shouldSwap ?? false,
    headerConfiguration,
  );

  const { pathname: homePagePathname, query: homePageQuery } = HomePage.route({
    edition,
    slide: "main",
  });

  useEffect(() => {
    if (!setShouldSwap) return;
    setShouldSwap(shouldSwap ?? false);
  }, [setShouldSwap, shouldSwap]);

  const [isLoading, startTransition] = useTransition();

  return (
    <>
      <Container className={className}>
        <HomeHeaderContainer ref={reference}>
          <HomeEdition />
          <HeaderContainer>
            <Wrapper>
              <NoSsr>
                <DynamicHomeNewsTicker />
              </NoSsr>
              <Content>
                <LeftFeatureContainer>
                  <IdleQueue>
                    <ActionButton
                      $responsiveVariants={{
                        desktopUp: "show",
                        homeDesktopUp: "hidden",
                        largeDesktopUp: "hidden",
                        mobileUp: "show",
                        tabletUp: "show",
                      }}
                      onClick={() => {
                        startTransition(() => {
                          setHamburgerMenuState(current => ({
                            ...current,
                            isOpen: !current.isOpen,
                          }));
                          sendGA4Tracking({
                            action: "click",
                            category: "menu",
                            customized_parameters: {
                              action_type: "open",
                              edition,
                              menu_type: "hamburger",
                            },
                            subcategory: "section",
                          });
                        });
                      }}
                    >
                      {isLoading ? (
                        <BaseImage
                          $aspectRatio="1"
                          $height="24px"
                          $objectFit="contain"
                          $width="24px"
                          alt={IconLoading.alt}
                          aria-label="loading"
                          lazyload={false}
                          src={IconLoading.src}
                          disableLinkingData
                        />
                      ) : (
                        <IconMenu />
                      )}
                    </ActionButton>
                  </IdleQueue>
                  {renderFeatures("left")}
                </LeftFeatureContainer>

                <StyledLogoLink
                  anchorProps={{ "aria-label": "SCMP homepage" }}
                  as={homeAsPath}
                  pathname={homePagePathname}
                  query={{ ...homePageQuery, ...mastheadQueryParameter }}
                >
                  <StyledLogo />
                </StyledLogoLink>
                {shouldSwap === false ? (
                  <RightFeatureContainer>{renderFeatures("right")}</RightFeatureContainer>
                ) : null}
              </Content>
              <OnelineMenuContainer>
                <NoSsr>
                  <StyledDynamicHeaderOnelineMenuContainer />
                </NoSsr>
              </OnelineMenuContainer>
            </Wrapper>
          </HeaderContainer>
        </HomeHeaderContainer>
      </Container>

      <StyledHomeMiniHeader $shouldSwap={shouldSwap} />
    </>
  );
};

HomeHeader.displayName = "HomeHeader";
